<template>
  <el-select v-if="rates" v-model="value" style="width:100%" size="large" @change="changeRates">
    <el-option
      v-for="rate in rates"
      :key="rate.id"
      :label="rate.name"
      :value="rate.id"
    />
  </el-select>
</template>

<script>
import {
  ElMessage,
} from 'element-plus';

export default {
  name: 'RatesSelect',
  props: {
    item: Object,
    rates: Object,
  },
  data: () => ({
    value: '',
  }),
  watch: {
    'item.userId': function () {
      this.value = this.item.tariffId;
    },
  },
  mounted() {
    this.value = this.item.tariffId;
  },
  methods: {
    async changeRates(tariffId) {
      const req = {
        tariffId,
        userId: this.item.userId,
      };
      try {
        await this.$axios.post(`/tariff/set-user-tariff?userId=${this.item.userId}&tariffId=${tariffId}`, req);
        this.$emit('get-rates-list');
        ElMessage({ message: 'Тариф успешно изменен', type: 'success' });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>

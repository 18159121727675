<template>
  <div>
    <div style="display: flex;">
      <el-input v-model="searchText" placeholder="Поиск по имени" :loading="loading" style="width: 20em;" size="large" class="mb4" @keydown.enter="getRequestsList(); pageNumber=1">
        <template #prefix>
          <el-icon>
            <Search />
          </el-icon>
        </template>
      </el-input>
      <el-button size="large" type="primary" class="ml3" @click="getRatesList(); pageNumber=1">
        <el-icon>
          <Search />
        </el-icon>
      </el-button>
    </div>
    <el-table style="width:100%" :data="items" :default-sort="{ prop: 'position', order: 'descending' }">
      <el-table-column prop="userPseudonym" label="Артист" />
      <el-table-column prop="tariffName" label="Текущий тариф" width="300px" />
      <el-table-column prop="tariffs" label="Новый тариф" width="300px">
        <template #default="scope">
          <RatesSelect :item="scope.row" :rates="rates" @get-rates-list="getRatesList" />
          <!-- <el-select v-if="rates" v-model="scope.row.tariffId" style="width:100%" size="large" @change="changeRates($event, scope.row)">
            <el-option
              v-for="item in rates"
              :key="item.id"
              :label="item.name"
              :value="item"
              :value-key="id"
            />
          </el-select> -->
        </template>
      </el-table-column>
      <!-- <el-table-column prop="amount" label="Вывод" width="98px" />
      <el-table-column prop="amount" label="Одобрить" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;" @click="approveRequest(scope.row.id)">
            <div style="border:1px rgba(255,255,255,0.3) solid; width:1.9444444444444444em; height:1.9444444444444444em; border-radius: 0.5555555555555556em; display: flex; align-items: center; justify-content: center;">
              <el-icon v-if="scope.row.status.value === 6" :size="27" color="#3290FF">
                <Select />
              </el-icon>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="Отклонить" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;" @click="declineRequest(scope.row.id)">
            <div style="border:1px rgba(255,255,255,0.3) solid; width:1.9444444444444444em; height:1.9444444444444444em; border-radius: 0.5555555555555556em; display: flex; align-items: center; justify-content: center">
              <el-icon :size="27">
                <CloseBold />
              </el-icon>
            </div>
          </div>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      background
      :page-size="pageSize"
      :page-count="totalPages"
      layout="prev, pager, next"
      :total="totalCount"
      @current-change="pageChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ElMessage,
} from 'element-plus';
import RatesSelect from '../rates/RatesSelect.vue';

export default {
  components: {
    RatesSelect,
  },
  name: 'FinanceRequestTable',
  // props: {
  //   items: Array,
  // },
  data: () => ({
    searchText: '',
    items: [],
    totalPages: 0,
    pageSize: 10,
    totalCount: 0,
    pageNumber: 1,
    rates: [],
  }),
  mounted() {
    this.getRates();
  },
  computed: {
    ...mapGetters(['userId']),
  },
  methods: {
    async getRates() {
      try {
        const { data } = await this.$axios.get('/tariff/get-all-tariffs');
        this.rates = data;
        this.getRatesList();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    pageChange(page) {
      this.pageNumber = page;
      this.getRatesList();
    },
    async getRatesList() {
      let query = '';
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      if (this.searchText) {
        query = `?artistName=${this.searchText}`;
      }
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/tariff/get-user-tariffs${query}`, params);
        this.totalPages = data.totalPages;
        this.totalCount = data.totalCount;
        this.items = data.items;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

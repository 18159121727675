<template>
  <div>
    <RateTable />
  </div>
</template>

<script>
import RateTable from '@/components/tables/RateTable.vue';

export default {
  name: 'Rate',
  components: {
    RateTable,
  },
  data: () => ({

  }),
};
</script>
